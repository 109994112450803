<template>
  <component
    :is="rootComponent"
    :to="link"
    :target="isNewWindow ? '_blank' : ''"
    class="st-mobile-menu-item"
    :data-t="dataT"
    prefetch
    @click="handleClick"
  >
    <div v-if="icon || iconLink" class="icon-wrapper">
      <StIcon v-if="iconLink" :icon-link="iconLink" class="left-icon" />
      <StIcon v-else :size="iconSize" :name="icon" class="left-icon" />
    </div>
    <div class="content">
      <span class="title">
        {{ title }}
      </span>
      <StCounter
        v-if="counter"
        :label="counter"
        bg-color="ghost"
        color="white"
        :live="live"
      />
      <slot />
      <StIcon name="chevron-right" :size="16" class="chevron-icon" />
    </div>
  </component>
</template>

<script setup lang="ts">
import type { RouteLocationRaw } from 'vue-router'
import NuxtI18nLink from '@st/i18n/components/NuxtI18nLink.vue'
import { useMobileLayout } from '../../composables'
import type { IconName } from '../StIcon/types'

const { closeMenu } = useMobileLayout()

const props = withDefaults(
  defineProps<{
    title: string
    icon?: IconName
    iconLink?: string | null
    iconSize?: number
    counter?: number
    link?: RouteLocationRaw
    dataT?: string
    isNewWindow?: boolean
    live?: boolean
  }>(),
  {
    iconSize: 24,
    icon: undefined,
    counter: undefined,
    link: undefined,
    dataT: 'st-mobile-menu-item',
    isNewWindow: false,
    live: true,
    iconLink: null,
  },
)

const rootComponent = computed(() => (props.link ? NuxtI18nLink : 'button'))

function handleClick() {
  if (props.link) closeMenu()
}
</script>

<style scoped>
.st-mobile-menu-item {
  all: unset;

  cursor: pointer;
  user-select: none;

  display: flex;
  gap: var(--spacing-150);
  align-items: center;

  box-sizing: border-box;
  width: 100%;
  padding-left: var(--spacing-150);

  color: var(--text-primary);
  text-decoration: none;
}

.content {
  display: flex;
  flex: 1 1 0;
  gap: var(--spacing-150);
  align-items: center;

  height: var(--spacing-600);
  padding-right: var(--spacing-150);

  border-bottom: var(--border-width-medium) solid var(--border-secondary);
}

.icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 24px;
  height: 24px;

  line-height: 0;
}

.title {
  flex: 1 1 0;
  font: var(--mobile-text-medium);
}

.left-icon {
  flex: 0 0 auto;
  color: var(--icon-secondary);
}

.chevron-icon {
  flex: 0 0 auto;
  color: var(--icon-secondary);
}
</style>
